<template>
  <div>
    <template v-if="!listVisible">
      <div class="card" style="display: flex">
        <el-button
          type="primary"
          plain
          @click="handleExportAll"
          style="height: 40px"
          :loading="exportLoading"
          icon="el-icon-upload2"
          >导出</el-button
        >
        <el-button
          type="primary"
          style="height: 40px"
          @click="handlePrint"
          :loading="printAllLoading"
          >打印</el-button
        >
        <div class="line"></div>
        <el-form :model="form" :inline="true" class="form" style="flex: 1">
          <el-form-item label="库存日期">
            <el-date-picker
              v-model="form.create_time[0]"
              align="right"
              type="date"
              @change="changeCreate_time"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
            <!-- <el-date-picker
              v-model="form.create_time"
              type="daterange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker> -->
          </el-form-item>
          <el-form-item label="门店">
            <el-select
              v-model="form.hierarchy_id"
              filterable
              :disabled="userInfo.hierarchy_type_id === 40"
              :multiple="userInfo.hierarchy_type_id === 30 ? true : false"
              collapse-tags
            >
              <template v-for="item in merchantList">
                <el-option
                  :value="item.hierarchy_id"
                  :label="item.hierarchy_name"
                  :key="item.hierarchy_id"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: auto">
            <div style="display: flex">
              <el-button
                type="primary"
                plain
                v-if="expand"
                @click="handleToggle"
                >收起</el-button
              >
              <el-button type="primary" plain v-else @click="handleToggle"
                >展开</el-button
              >
              <ExportConf function_code="historyStock" />
              <el-button
                type="primary"
                @click="handleSearch"
                style="margin-left: 10px"
                >搜索</el-button
              >
              <el-button @click="handleReset">重置</el-button>
              <PrintConf
                function_code="historyStockPrint"
                @onRowList="handlePrintRow"
                v-show="false"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="table">
        <el-table
          border
          :data="tableData"
          stripe
          v-loading="loading"
          height="700"
          @row-dblclick="handleDalclick"
          element-loading-text="数据量过大，加载可能需要一两分钟..."
        >
          <template v-for="(item, index) in columnList">
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'create_time'">
                  <span v-if="scope.row.id">{{
                    scope.row[item.field_alias]
                  }}</span>
                </template>
                <template v-else-if="item.field_alias === 'hierarchy_name'">
                  <span v-if="scope.row.id">合计</span>
                  <span v-else>{{ scope.row[item.field_alias] }}</span>
                </template>
                <template v-else-if="item.field_alias === 'id'">
                  {{ scope.row[item.field_alias] }}
                </template>
                <template v-else>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column align="center" field_alias="operation" label="操作">
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option
                    :label="scope.row.txt ? scope.row.txt : '导出'"
                    :value="1"
                    v-loading="scope.row.loading"
                  ></el-option>
                  <el-option label="打印" :value="2"></el-option>
                  <el-option label="库存信息" :value="3"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class='pagina'>
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout='total, sizes, prev, pager, next, jumper'
          :total='tableData.total'
          :page-size='limit'
          :page-sizes="$store.state.pageSizes"
          @current-change='handleCurrentChange'
        ></el-pagination>
      </div> -->
    </template>
    <List
      v-else
      @onBack="handleBack"
      :row="currRow"
      :time="currRow.create_time"
    />
    <LocalPrint
      :show="false"
      :loading="printLoading"
      :printRows="printRows"
      :printData="printData"
      title="历史库存单"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import {
  getListReq,
  getHistoryListReq,
  historyInvPrintReq,
  getMerchantListReq,
  exportReq,
} from "@/api/goods/historyInv/index";
import moment from "moment";
import storage from "good-storage";
import List from "./List.vue";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import ExportConf from "@/components/exportConf/Index.vue";

export default {
  name: "HistoryInv",
  data() {
    return {
      printAllLoading: false,
      newArr: [],
      noExpandArr: [],
      expand: true, // 是否展开
      exportLoading: false,
      merchantList: [], // 门店列表
      userInfo: {},
      printLoading: false,
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      currRow: {},
      listVisible: false,
      loading: false,
      form: {
        create_time: [new Date(),new Date()],
      },
      columnList: [
        { field_text: "序号", field_alias: "id" },
        { field_text: "库存日期", field_alias: "create_time" },
        { field_text: "门店", field_alias: "hierarchy_name" },
        { field_text: "数量", field_alias: "num" },
        { field_text: "净金重", field_alias: "gold_weight" },
        { field_text: "主石重", field_alias: "main_stone_weight" },
        { field_text: "总重", field_alias: "weight" },
        { field_text: "标签价", field_alias: "price_tag" },
        { field_text: "成本价", field_alias: "total_cost_price" },
      ],
      tableData: [],
      pickerOptions0: {//日期选择器限制范围
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6
          }
      },
      value1:"",
      limit: this.$store.state.pageSizes[0],
      detailRows: [], // 详情打印的行
      allRows: [
        {
          field: "id",
          field_alias: "id",
          field_text: "序号",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "create_time",
          field_alias: "create_time",
          field_text: "库存日期",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "hierarchy_name",
          field_alias: "hierarchy_name",
          field_text: "门店",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "num",
          field_alias: "num",
          field_text: "数量",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "gold_weight",
          field_alias: "gold_weight",
          field_text: "净金重",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "main_stone_weight",
          field_alias: "main_stone_weight",
          field_text: "主石重",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "weight",
          field_alias: "weight",
          field_text: "总重",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "price_tag",
          field_alias: "price_tag",
          field_text: "标签价",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
        {
          field: "total_cost_price",
          field_alias: "total_cost_price",
          field_text: "成本总价",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 23,
        },
      ], // 整体打印的行
    };
  },
  components: {
    List,
    PrintConf,
    LocalPrint,
    ExportConf,
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
  },
  activated() {
    if (this.$route.params.create_time) {
      this.form = {
        create_time: this.$route.params.create_time,
        hierarchy_id: this.$route.params.hierarchy_id,
      };
      this.getList();
    }
  },
  methods: {
    // 打印
    handlePrint() {
      if (!this.tableData.length) {
        this.$message.warning("当前没有数据可以打印");
        return;
      }
      this.printRows = this.allRows;
      console.log(this.printRows);
      this.printData.list = this.tableData;
      this.localPrintVisible = true;
    },
    handleToggle() {
      this.expand = !this.expand;
      if (!this.expand) {
        this.tableData = this.noExpandArr;
      } else {
        this.tableData = this.newArr;
      }
    },
    handleExportAll() {
      if (!this.form.create_time[0]) {
        this.$message.warning("请选择库存日期");
        return;
      }
      this.exportLoading = true;

      exportReq({
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.exportLoading = false;
          var filename = "历史库存列表导出.csv";
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
          // 如果是门店
          if (this.userInfo.hierarchy_type_id === 40) {
            this.form.hierarchy_id = res.data[0].hierarchy_id;
          }
        }
      });
    },
    changeCreate_time(val){
      this.form.create_time[0]=val;
      this.form.create_time[1]=val;
      console.log(val)
    },
    getHistoryInvPrint(row) {
      this.printLoading = true;
      // this.$notify({
      //   title: '提示',
      //   type: 'warning',
      //   message: '历史库存打印请求中...',
      //   duration: 0,
      // });
      historyInvPrintReq({
        id: row.id,
        ...this.cond,
        create_time: row.create_time,
        hierarchy_id: Array.isArray(row.hierarchy_id)
          ? row.hierarchy_id
          : [row.hierarchy_id],
      })
        .then((res) => {
          if (res.code === 1) {
            this.printLoading = false;
            this.currRow.select = "";
            // this.$notify.close();
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: "库存日期", value: data.print_time, width: "24%" },
              { txt: "门店", value: data.hierarchy_name, width: "24%" },
            ];
            res.data.bottom = [];
            res.data.top.push(arr1);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: "合计",
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split(".");
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== "index") {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });

            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.printLoading = false;
        });
    },
    handlePrintRow(rows) {
      this.detailRows = rows;
      this.printRows = rows;
    },
    /*
     *  导出
     */
    handleExport(row) {
      // this.$notify({
      //   title: '提示',
      //   type: 'warning',
      //   message: '历史库存导出中...',
      //   duration: 0,
      // });
      row.txt = "导出中...";
      this.exportLoading = true;
      const data = {
        id: row.id,
        export: 1,
        function_code: "historyStock",
        create_time: row.create_time,
        hierarchy_id: Array.isArray(row.hierarchy_id)
          ? row.hierarchy_id
          : [row.hierarchy_id],
      };
      getHistoryListReq(data)
        .then((res) => {
          this.currRow.select = "";
          row.txt = "";
          this.$notify.close();
          this.exportLoading = false;
          var filename = `历史库存${row.create_time}.csv`;
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          row.txt = '';
          this.currRow.select = '';
          this.exportLoading = false;
        });
    },
    handleDalclick(row) {
      this.currRow = row;
      this.listVisible = true;
    },
    handleBack() {
      this.listVisible = false;
      this.currRow.select = "";
    },
    handleChange(e, row) {
      this.currRow = row;
      if (e === 1) {
        this.handleExport(row);
      }
      if (e === 2) {
        this.printRows = this.detailRows;
        this.getHistoryInvPrint(row);
      }
      if (e === 3) {
        this.listVisible = true;
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    getList() {
      this.loading = true;
      getListReq({
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            const newArr = res.data;
            // 一个不展开的列表
            const noExpandArr = res.data.filter((item) => {
              return item.id;
            });
            this.noExpandArr = noExpandArr;
            this.newArr = newArr;
            console.log(noExpandArr);
            // 默认展开
            this.tableData = newArr;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSearch() {
      if (!this.form.create_time[0]) {
        this.$message.warning("请选择库存日期");
        return;
      }
      if (this.form.create_time.length === 2) {
        console.log(this.form.create_time);
        const days = moment(this.form.create_time[1]).diff(
          moment(this.form.create_time[0]),
          "days",
          true
        );
        if (days > 30) {
          this.$message.warning("日期范围不得超过30天。");
          return;
        }
      }
      if (this.userInfo.hierarchy_type_id === 30 && !this.form.hierarchy_id) {
        this.$message.warning("请选择门店");
        return;
      }
      this.getList();
    },
    handleReset() {
      this.form = {
        create_time: [new Date(),new Date()]
      };
      this.tableData = [];
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  display: flex;
  /deep/ .el-form-item {
    margin-bottom: 0px !important;
  }
}
</style>
