<template>
  <div>
    <div class="content" v-if="!visiable">
      <div class="card" style="border-bottom: 0px;">
        <el-page-header @back="goBack" content="库存信息"></el-page-header>
      </div>
      <div class="card" style="display: flex; border-radius: 0px;">
        <div class="left" style="flex-shrink: 0; display: flex;">
          <el-button
            :loading="exportLoading"
            type="primary"
            @click="handleExport"
            icon="el-icon-upload2"
            style="margin-right: 10px; height: 40px;"
            >导出</el-button
          >
          <LocalPrint :show="true" :loading="printLoading" :printRows="printRows" :printData="printData" title="历史库存单" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" @onPrint="handlePrint" />
          <!-- <el-button
            type="primary"
            plain
            @click="handleGoodsAll"
            icon="el-icon-download"
            >批量导入货品图</el-button
          > -->
        </div>
        <div class="line" style="margin: none !important"></div>
        <div class="middle">
          <Cond @onCond="handleCond" function_code="historyStock" @setCond="handleGetCond" />
        </div>
        <div class="right" style="flex-shrink: 0; display: flex">
          <TableConf function_code="historyStock" @onRowList="handleRowList" />
          <ExportConf function_code="historyStock" />
          <PrintConf function_code="historyStockPrint" @onRowList="handlePrintRow" />
        </div>
      </div>
      <div class="table" v-if="rowList.length">
        <u-table
          :data="tableData"
          stripe
          :border="false"
          v-loading="loading"
          height="650"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in rowList">
            <u-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
            </u-table-column>
            <u-table-column
              v-else-if="item.field_alias === 'goods_name'"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div style="display: inline-block">
                  {{ scope.row[item.field_alias] || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img
                      :src="item"
                      :key="item"
                      style="margin-left: 5px; vertical-align: middle"
                    />
                  </template>
                </div>
              </template>
            </u-table-column>
            <u-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img :src="noImg" />
                  </div>
                </div>
                <div v-else-if="item.field_alias === 'warning_state'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-if="
                      scope.row[item.field_alias] !== 10 &&
                      scope.row[item.field_alias] !== 20 &&
                      scope.row[item.field_alias] !== 30
                    "
                  >
                    <div
                      style="
                        background-color: #21bc36;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 10"
                  >
                    <div
                      style="
                        background-color: #ffdf04;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 20"
                  >
                    <div
                      style="
                        background-color: #ff9000;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="警报等级依次为：绿<黄<橙<红"
                    placement="top-start"
                    v-else-if="scope.row[item.field_alias] === 30"
                  >
                    <div
                      style="
                        background-color: #f4390d;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin: 0 auto;
                      "
                    ></div>
                  </el-tooltip>
                </div>
                <div v-else>
                  {{ scope.row[item.field_alias] || "--" }}
                </div>
              </template>
            </u-table-column>
          </template>
          <!-- <u-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">详情</el-button>
            </template>
          </u-table-column> -->
        </u-table>
      </div>
      <div class="pagina" v-if="rowList.length">
        <el-pagination
          :current-page="page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    <Detail :entry_id="currRow.entry_id" :time="this.time" v-else @hide="handleHide" />
  </div>
</template>

<script>
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import ExportConf from "@/components/exportConf/Index.vue";
import Detail from './Detail.vue';
import {
  getDetailTitle,
  getGoodsDetails,
} from "@/api/goods/goodsStock/goodsDetailedlist/index";
import { getHistoryListReq as getList, historyInvPrintReq } from '@/api/goods/historyInv/index';
import Cookies from "js-cookie";
import storage from "good-storage";
import { Message } from "element-ui";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      printLoading: false,
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      currRow: {},
      noImg: require('@/assets/images/no_img.png'),
      exportLoading: false,
      tableData: [],
      activeName: "first",
      rowList: [],
      visiable: false,
      cond: {},
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      loading: false,
      goodsInfo: [],
      total: 0,
      sale_price: "", // 标签价
      goods_name: "", // 品名
      goods_pic: null, // 图片
      formLabelWidth: "130px",
      goodAllImgVisible: false,
      goodAllImgForm: {},
      import_pic_type: null,
      otherList: [
        {
          prop: "",
          text: "期初库存",
          value: "",
        },
        {
          prop: "",
          text: "入库",
          value: "",
        },
        {
          prop: "",
          text: "调出",
          value: "",
        },
        {
          prop: "",
          text: "调入",
          value: "",
        },
        {
          prop: "",
          text: "销售",
          value: "",
        },
        {
          prop: "",
          text: "退货",
          value: "",
        },
        {
          prop: "",
          text: "退库",
          value: "",
        },
        {
          prop: "",
          text: "拆改",
          value: "",
        },
        {
          prop: "",
          text: "合计",
          value: "",
        },
      ],
      logList: [],
      rowList2: [
        {
          label: "操作时间",
          prop: "date",
        },
        {
          label: "操作记录",
          prop: "msg",
        },
        {
          label: "操作人",
          prop: "store_user_name",
        },
      ],
      goodImgForm: {},
      goodsTotal: {},
      good_img: "",
      goodImgVisible: false,
      goods_id: '',
      token: "",
      baseUrl: "",
    };
  },
  components: {
    Cond,
    TableConf,
    ExportConf,
    Detail,
    PrintConf,
    LocalPrint,
  },
  props: {
    row: {
      type: Object,
      default: () => {
        return {};
      }
    },
    time: String,
  },
  mounted() {
    if (this.$route.params.type) {
      if (this.$route.params.type === "detail") {
        this.goods_id = this.$route.params.key;
        this.visiable = true;
      }
    }
  },
  created() {
    if (this.$route.params.type && this.$route.params.type === "salesOrder") {
      this.visiable = true;
      this.goods_id = this.$route.params.goods_id;
    }
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getList();
    // this.getGoodsCount();
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    if (env === "development") {
      this.baseUrl = "https://api.test.etouch.vip/";
    } else {
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "https://api.test.etouch.vip/";
      }
      if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top/";
      }
      if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip/";
      }
    }
    console.log(this.row);
  },
  methods: {
    handlePrint() {
      this.getHistoryInvPrint();
    },
    handleGetCond(cond) {
      this.cond = cond;
    },
    getHistoryInvPrint(cond) {
      this.printLoading = true;
      historyInvPrintReq({ id: this.rowId, ...cond, create_time: this.row.create_time, hierarchy_id: Array.isArray(this.row.hierarchy_id) ?  this.row.hierarchy_id : [this.row.hierarchy_id] })
        .then((res) => {
          if (res.code === 1) {
            this.printLoading = false;
            this.currRow.select = '';
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '库存日期', value: data.print_time, width: '24%' },
              { txt: '门店', value: data.hierarchy_name, width: '24%' },
            ];
            res.data.bottom = [];
            res.data.top.push(arr1);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split('.');
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== 'index') {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });

            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.printLoading = false;
        });

    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handleHide() {
      this.visiable = false;
    },
    handleDetail(row) {
      this.currRow = row;
      this.visiable = true;
    },
    goBack() {
      this.$emit('onBack');
    },
    /*
     *  获取货品清单数据
     */
    getList() {
      this.loading = true;
      getList({ id: this.row.id, ...this.cond, page: this.page, page_num: this.page_num, create_time: this.row.create_time, hierarchy_id: this.row.hierarchy_id }).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          res.data.list.map((item) => {
            for (var key in item) {
              if (key !== "goods_pic") {
                if (!item[key]) {
                  item[key] = "--";
                }
              }
            }
            return item;
          });
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /* 
    *  关闭详情
    */
    handleCloseDetail() {
      console.log('ces');
      this.visiable = false;
    },
    // getGoodsCount() {
    //   getGoodsCount({ ...this.cond }).then((res) => {
    //     if (res.code === 1) {
    //       this.goodsTotal = res.data;
    //     }
    //   });
    // },
    /*
     *  关闭详情弹窗
     */
    cancelDia() {
      this.activeName = null;
      this.visiable = false;
      this.getList();
      // this.getGoodsCount();
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = {};
      console.log('重置')
      this.getList();
      // this.getGoodsCount();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
      // this.getGoodsCount();
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.page_num = val;
      this.getList();
      // this.getGoodsCount();
    },
    handleGoodsAll() {
      this.goodAllImgVisible = true;
    },
    /*
     *  导出
     */
    handleExport() {
      this.exportLoading = true;
      const data = {
        id: this.rowId,
        export: 1,
        function_code: "historyStock",
        ...this.cond,
        create_time: this.row.create_time,
        hierarchy_id: Array.isArray(this.row.hierarchy_id) ? this.row.hierarchy_id : [this.row.hierarchy_id],
      };
      getList(data)
        .then((res) => {
          this.exportLoading = false;
          var filename = `历史库存${this.time}.csv`;
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /*
     *  跳转到入库单
     */
    toWareHousing(val) {
      this.$router.push({
        // path: "/goods/godownEntry/godownEntryList",
        // query: { entry_number: val },
        name:'godownEntryList',
        params:{entry_number: val},
      });
    },
    handleDalclick(row) {
      // this.currRow = row;
      // this.visiable = true;
    },
    /*
     *  操作
     */
    
    handleTab() {},
    /*
     *  保存货品图片
     */
    sumbitGoodImg() {
      const data = {
        file: this.good_url,
      };
      uploadGoodsPic(data).then((res) => {
        if (res.code === 1) {
        }
      });
    },
    /*
     *  关闭上传图片弹窗
     */
    cancelGoodImg() {
      this.goodImgVisible = false;
      this.good_img = "";
      this.getList();
      // this.getGoodsCount();
    },
    cancelGoodAllImg() {
      this.goodAllImgVisible = false;
      this.getList();
      // this.getGoodsCount();
    },
    handleAllImgSuccess(res, file) {
      if (res.code === 1) {
        this.$message({
          type: "success",
          message: "上传成功！",
        });
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    handleImgSuccess(res, file) {
      if (res.code === 1) {
        this.good_img = res.data[0].path;
      } else {
        // 提示错误
        Message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  flex: 1;
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border: 1px solid #ddd;
  border-top: none;
  border-left: none;
  border-right: none;

  // border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 25%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
/deep/ .table-expand {
  width: 11.13%;
  color: #2791ff;
  text-align: center;
  border-right: 1px solid #ddd;
}

.goodsTitle {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .name {
    width: 100px;
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
  }
  .price {
    font-size: 16px;
    text-align: right;
    width: 260px;
  }
}

/deep/ .goodsInfo {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    width: 50%;
    line-height: 38px;

    .left {
      color: #909399;
      width: 30%;
      text-align: left;
      font-size: 14px;
    }
    .right {
      width: 70%;
      font-size: 14px;
    }
  }
}
/deep/ .el-tabs__item {
  font-size: 16px !important;
  line-height: 20px;
}
/deep/ .el-dialog__body {
  padding: 30px !important;
}
.image-slot {
  width: 50px;
  height: 50px;
  margin: 5px auto 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
/deep/ .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
/deep/ .avatar {
  width: 290px;
  height: 170px;
  display: block;
}
/deep/ .avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}

/deep/ .auditSuccess {
  padding-bottom: 20px;
  margin-top: 10px;
  height: 450px;
  background-color: white;

  /deep/ .auditImg {
    margin: 10px auto 0 45%;
    width: 169px;
    height: 142px;
  }

  /deep/ .audit_text {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    span {
      font-size: 22px;
      color: #5493f5;
    }
  }
}
</style>
